$primarycolor: #004c97;
:root {
  font-size: 8px;
}
body {
  font-size: 1em;
}
.slotSpanish {
  font-size:12px !important;
  font-weight: 600;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-btn-primary {
  background-color: $primarycolor;
}
.ant-menu-item-selected {
  background-color:#004c97 !important ;
}
.ant-drawer-title {
  margin:0 0 0 14% !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #696be4;
  color: $primarycolor;
  font-weight: bold;
}

.logo {
  background: rgba(255, 255, 255, 0.2);
  margin: 3rem 3.5rem;
  object-fit: contain;
}
.content {
  margin: -190px 6px 6px 6px;
  padding: 24px;
  background: #fff;
  min-height: auto;
}

.dash_content {
  margin: 28px 6px 6px 6px;
  padding: 24px;
  background: #fff;
  min-height: auto;
}
.comanfile_upload {
  border: 1px solid #d9d9d9;
  min-height: 5.5em;
  border-radius: 0.5em;
  position: relative;
  .icon {
    font-size: 3em;
    color: #d9d9d9;
  }
}
.slot_img_upload {
  border: 1px solid #d9d9d9;
  min-height: 9.5em;
  border-radius: 0.5em;
  position: relative;
}
.anticon[tabindex] {
  cursor: pointer;
  padding: 0px 0.3em;
  font-size: 1.2em;
}
.icon_size {
  font-size: 3em !important;
}
.ant-layout-sider {
  background: "#fff";
}
.sidebar_icon {
  font-size: 2.5em !important;
  margin-left: 1em;
  color: whitesmoke;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #646ee4;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > * {
  color: white;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #212529;
  background: #ffffff;
}

.ant-layout-sider-children {
  background: #ffffff;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
  color: #7f7d79;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #004C97, #825ee4) !important;
}
.ant-layout-header {
  height: 257px;
  padding: 0;
  line-height: 64px;
}

.anticon {
  vertical-align: baseline;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 11px 16px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.page_title {
  color: white;
  font-size: 3em;
  font-family: serif;
  text-transform: capitalize;
}

.ant-spin-nested-loading {
    position: relative;
    display: contents;
}

.react-datepicker__input-container input{
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 10px;
  .ant-collapse-arrow {
    display: none;
  }
}
  
