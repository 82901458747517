.slot_card {
  position: absolute;
  width: 4em;
  height: 4em;
}
.slot_detail {
  font-size: 15px;
  font-family: sans-serif;
  line-height: 4rem;
}
.dz-progress {
  display: none;
}
.dz-error-message {
  display: none;
}
.dz-success-mark {
  display: none;
}
.drop_zon {
  border: 1px solid #d9d9d9;
  padding: 0px 1em;
  height: 10em;
  width: 10em;
  border-radius: 5px;
}

.dz-message {
  display: none;
}
.dz-error-mark {
  display: none;
}

.slot_img{
  display: block;
  width: 27%;
  border: 1px solid #d9d9d9;
  min-height: 9em;
  border-radius: 7px;

}