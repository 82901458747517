.ql-toolbar.ql-snow {
  border-radius: 1em 1em 0em 0em;
}
.ql-container.ql-snow {
  border-radius: 0em 0em 1em 1em;
  min-height: 55vh;
  overflow-y: auto;
}
.file_upload{
      border: 1px #d9d9d9 solid;
      border-radius: 0.5em;
      min-height: 22em;
}
