@media screen and (max-width: 600px) {
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td:not(.mf-footer),
  #no-more-tables tr {
    display: block;
  }

  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
    height: auto;
    margin-bottom: 1em;
  }

  #no-more-tables td:not(.mf-footer) {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: center;
  }

  #no-more-tables td span:before {
    position: absolute;
    top: 8px;
    left: 5%;
    width: 45%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    font-family: "circebold";
  }

  #no-more-tables td span:before {
    content: attr(title);
  }

  ant-table-expand-icon-th,
  .ant-table-row-expand-icon-cell {
    width: -webkit-fill-available !important;
    /* min-width: 50px; */
    text-align: right;
  }

  .ant-table-row-expand-icon-cell div::before {
    content: attr(aria-label);
    margin-left: -55vw;
    position: absolute;
  }

  .anticon-edit {
    position: absolute;
    margin-left: -40vw;
  }

  .recharts-surface {
    position: absolute;
    width: 92vw;
    margin: -1em -3.5em;
  }

  .login_card {
    width: -webkit-fill-available !important;
  }
  
  .title_top {
    margin-top: 10em;
  }
}
