.bg-gradient-logo {
  // background: linear-gradient(87deg, #b1abff, #4e12f1) !important;
  background: white;
}

.logo_login {
  margin: 0.5em;
  object-fit: contain;
}

.login_dash_content {
  margin: 5px 6px 6px 6px;
  padding: 24px;
  background: #fff;
  min-height: auto;
}
